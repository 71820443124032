import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";
import { useDispatch } from "react-redux";
import { loginAdmin } from "../../redux/action";
const AdminSignIn = () => {
  const [emailForLogin, setEmailForLogin] = useState("");
  const [passwordForLogin, setPasswordForLogin] = useState("");
  const [loginErrorAlert, setLoginErrorAlert] = useState(false);
  const [loginSuccessAlert, setLoginSuccessAlert] = useState(false);
  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const handleLogin = async (e) => {
    e.preventDefault();
    const loggedWith = await dispatch(
      loginAdmin({
        login: emailForLogin,
        password: passwordForLogin,
      })
    );
    if (loggedWith.status === "success") {
      setLoginSuccessAlert(true);
      setTimeout(() => {
        setLoginSuccessAlert(false);
        loggedWith.id && navigateTo(`/`);
      }, 2000);
    } else {
      setLoginErrorAlert(true);
      setTimeout(() => {
        setLoginErrorAlert(false);
      }, 2000);
    }
  };

  console.log("ADMIN SIGNIN")
  return (
    <div id="signUpSignIn">
      <div className="header-wrapper">
        <div className="header">LOGIN</div>
      </div>
      <div className="main-content">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12 sign-in">
            <h1>Sign In</h1>
            <form onSubmit={handleLogin}>
              <div className="form-group">
                <label htmlFor="email-login">Login or E-mail</label>
                <input
                  value={emailForLogin}
                  onChange={(e) => setEmailForLogin(e.target.value)}
                  type="text"
                  className="form-control"
                  id="email-login"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
              </div>
              <div className="form-group">
                <label htmlFor="password-login">Password</label>
                <input
                  value={passwordForLogin}
                  onChange={(e) => setPasswordForLogin(e.target.value)}
                  type="password"
                  className="form-control"
                  id="password-login"
                  placeholder="Password"
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
              {loginErrorAlert && (
                <div className=" mt-5 alert alert-danger" role="alert">
                  Password or Username is incorrect. Please try again.
                </div>
              )}
              {loginSuccessAlert && (
                <div className=" mt-5 alert alert-success" role="alert">
                  Success!
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSignIn;
