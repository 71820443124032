import React, { useState } from "react";
import { MdPhotoCamera } from "react-icons/md";
import { AiOutlineUpload } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Cookies from "universal-cookie";
const prodURL = process.env.REACT_APP_PROD_URL;
const devURL = process.env.REACT_APP_DEV_URL;
const environment = process.env.REACT_APP_ENVIRONMENT;
const url = environment === "production" ? prodURL : devURL;

const EditDealerListing = ({ type, selectedUser, setSelected }) => {
  const [name, setName] = useState("");
  const [login, setLogin] = useState("");
  const [image, setImage] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [dealerId, setDealerId] = useState("");
  const [logo, setLogo] = useState(null);
  const [phone_number, setPhone_Number] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [website, setWebsite] = useState("");
  const [facebook, setFacebook] = useState("");
  const [twitter, setTwitter] = useState("");
  const [youtube, setYoutube] = useState("");
  const [role, setRole] = useState("user");
  const [map_link, setMapLink] = useState("");
  const [coins, setCoins] = useState(0);
  const cookies = new Cookies();
  const token = cookies.get("jwt");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMesssage, setErrorMessage] = useState("");
  const getUser = async (id) => {
    try {
      const response = await fetch(`${url}/dealer/${id}`, {
        method: "GET",
      });
      if (response.ok) {
        const data = await response.json();
        const { dealer } = data.data;
        type !== "add" && setName(dealer.name);
        type !== "add" && setLogin(dealer.login);
        type !== "add" && setDealerId(dealer._id);
        type !== "add" && setLogo(dealer.logo);
        type !== "add" && setRole(dealer.role);
        type !== "add" && dealer.address && setAddress(dealer.address);
        type !== "add" && dealer.postcode && setPostcode(dealer.postcode);
        type !== "add" && dealer.map_link && setMapLink(dealer.map_link);

        type !== "add" && dealer.coins && setCoins(dealer.coins);
        type !== "add" && dealer.email && setEmail(dealer.email);
        type !== "add" && dealer.links && setWebsite(dealer.links.web_site);
        type !== "add" && dealer.links && setFacebook(dealer.links.facebook);
        type !== "add" && dealer.links && setTwitter(dealer.links.twitter);
        type !== "add" && dealer.links && setYoutube(dealer.links.youtube);

        type !== "add" &&
          dealer.phone_number &&
          setPhone_Number(dealer.phone_number);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    typeof selectedUser !== "undefined" && getUser(selectedUser._id);
  }, []);

  const updateDealer = async (
    id,
    name,
    login,
    logo,
    currentPassword,
    newPassword
  ) => {
    let formData = new FormData();
    // let file = ImageUpld.file;
    image && formData.append("logo", image);
    name !== "" && formData.append("name", name);
    login !== "" && formData.append("login", login);
    phone_number !== "" && formData.append("phone_number", phone_number);
    email !== "" && formData.append("email", email);
    newPassword !== "" && formData.append("new_password", newPassword);

    map_link !== "" && formData.append("map_link", map_link);
    const links = {
      web_site: website !== "" ? website : "#",
      facebook: facebook !== "" ? facebook : "#",
      twitter: twitter !== "" ? twitter : "#",
      youtube: youtube !== "" ? youtube : "#",
    };

    address !== "" && formData.append("address", address);
    postcode !== "" && formData.append("postcode", postcode);
    formData.append("role", role);
    formData.append("links", JSON.stringify(links));
    formData.append("coins", coins);

    const response = await fetch(`${url}/dealer/${id}`, {
      method: "PATCH",
      body: formData,
      headers: {
        authorization: `${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setImage(null);
      typeof selectedUser !== "undefined" && getUser(selectedUser._id);
      setSuccess(true);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        setSuccess(false);
        setSelected("dealer-listing");
      }, 3000);
    } else {
      const data = await response.json();
      console.log("error: ", data);
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const createDealer = async () => {
    let formData = new FormData();
    image && formData.append("logo", image);
    name !== "" && formData.append("name", name);
    login !== "" && formData.append("login", login);
    newPassword !== "" && formData.append("password", newPassword);
    phone_number !== "" && formData.append("phone_number", phone_number);
    email !== "" && formData.append("email", email);
    const links = {
      web_site: website !== "" ? website : "#",
      facebook: facebook !== "" ? facebook : "#",
      twitter: twitter !== "" ? twitter : "#",
      youtube: youtube !== "" ? youtube : "#",
    };
    role === "dealer" && address !== "" && formData.append("address", address);
    role === "dealer" &&
      postcode !== "" &&
      formData.append("postcode", postcode);
    role === "dealer" &&
      map_link !== "" &&
      formData.append("map_link", map_link);

    formData.append("role", role);
    role === "dealer" && formData.append("links", JSON.stringify(links));
    // role === "dealer" && formData.append("coins", coins);

    const response = await fetch(`${url}/dealer/create`, {
      method: "POST",
      body: formData,
      headers: {
        authorization: `${token}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setImage(null);
      typeof selectedUser !== "undefined" && getUser(selectedUser._id);
      setSuccess(true);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
      setTimeout(() => {
        setSuccess(false);
        setSelected("dealer-listing");
      }, 3000);
    } else {
      const data = await response.json();
      if (data.error.code === 11000) {
        console.log("error: ", data.error.code);
        setErrorMessage(
          "Account with similar username or email already exists. Please log in or sign up with another email and username"
        );
      }
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 3000);
    }
  };

  const handleAccountUpdate = async (e) => {
    e.preventDefault();
    type === "add" && createDealer();
    typeof selectedUser !== "undefined" &&
      updateDealer(
        selectedUser._id,
        name,
        login,
        logo,
        currentPassword,
        newPassword
      );
  };

  return (
    <div className="mb-5">
      <h2 className="px-4 py-3">
        {type === "add" ? "ADD A DEALER" : "EDIT ACCOUNT INFORMATION"}
      </h2>
      <form onSubmit={handleAccountUpdate} className="row">
        <div className="col-sm-12 col-md-12 col-lg-6">
          <h3>ACCOUNT INFORMATION</h3>
          <div className="card-information">
            <div className="form-group">
              <label htmlFor="name">
                Full Name <span className="text-danger">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                aria-describedby="name"
                required={type === "add" ? true : false}
              />
            </div>
            <div className="row">
              <div className="upload-photos">
                <label htmlFor="upload-photo">
                  <div
                    className="icon"
                    style={logo ? { background: "none" } : null}
                  >
                    {logo ? (
                      <img
                        src={`https://dev.wavcompare.com/uploads/${logo}`}
                        alt=""
                        className="img-fluid"
                        style={{ marginBottom: "10px", maxWidth: "200px" }}
                        loading="lazy"
                      />
                    ) : (
                      <MdPhotoCamera />
                    )}

                    <div>Choose Photo</div>
                  </div>
                  <div className="text">
                    <AiOutlineUpload />
                    Upload {image && "| " + image.name}
                  </div>
                </label>
                <input
                  type="file"
                  onChange={(e) => setImage(e.target.files[0])}
                  id="upload-photo"
                  style={{ display: "none" }}
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="account-name">Login</label>
              <input
                type="text"
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                className="form-control"
                id="account-name"
                placeholder="Account name"
                aria-describedby="accountName"
                required={type === "add" ? true : false}
              />
            </div>
            <div className="form-group">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                value={phone_number}
                onChange={(e) => setPhone_Number(e.target.value)}
                className="form-control"
                id="phone"
                placeholder="Insert your phone number"
                aria-describedby="phoneNumber"
                required={type === "add" ? true : false}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                id="email"
                placeholder="Insert your email"
                aria-describedby="email"
                required={type === "add" ? true : false}
              />
            </div>
            {type !== "add" && (
              <div className="form-group">
                <label htmlFor="coins">Credits</label>
                <input
                  type="number"
                  value={coins}
                  onChange={(e) => setCoins(e.target.value)}
                  className="form-control"
                  id="coins"
                  placeholder="Coins"
                  aria-describedby="coins"
                />
              </div>
            )}{" "}
            <div className="form-group">
              <div className="form-group">
                <label htmlFor="body-type">
                  Role <span className="text-danger">*</span>
                </label>
                <select
                  value={role}
                  required={type === "add" ? true : false}
                  onChange={(e) => setRole(e.target.value)}
                  className="form-control"
                  id="body-type"
                >
                  <option value="user">User</option>
                  <option value="dealer">Dealer</option>
                </select>
              </div>
              {role === "dealer" && (
                <div>
                  <div className="form-group">
                    <label htmlFor="address">
                      Address <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={address}
                      required
                      onChange={(e) => setAddress(e.target.value)}
                      className="form-control"
                      id="address"
                      placeholder="Enter your address"
                      aria-describedby="address"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="postcode">
                      Post Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={postcode}
                      onChange={(e) => setPostcode(e.target.value)}
                      className="form-control"
                      id="postcode"
                      required
                      placeholder="Enter your postal code"
                      aria-describedby="accountName"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="postcode">
                      Map Link <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={map_link}
                      onChange={(e) => setMapLink(e.target.value)}
                      className="form-control"
                      id="postcode"
                      required
                      placeholder="Enter your postal code"
                      aria-describedby="accountName"
                    />
                  </div>
                  <h3 className="my-3">Links to your social media accounts</h3>
                  <div className="form-group">
                    <label htmlFor="website">Website</label>
                    <input
                      type="text"
                      value={website}
                      onChange={(e) => setWebsite(e.target.value)}
                      className="form-control"
                      id="website"
                      required={
                        type === "add" && role === "Dealer" ? true : false
                      }
                      placeholder="Enter your website link"
                      aria-describedby="website"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="facebook">Facebook</label>
                    <input
                      type="text"
                      value={facebook}
                      onChange={(e) => setFacebook(e.target.value)}
                      required={
                        type === "add" && role === "Dealer" ? true : false
                      }
                      className="form-control"
                      id="facebook"
                      placeholder="Enter your Facebook account link"
                      aria-describedby="facebook"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="twitter">Twitter</label>
                    <input
                      type="text"
                      value={twitter}
                      onChange={(e) => setTwitter(e.target.value)}
                      className="form-control"
                      id="twitter"
                      placeholder="Enter your Twitter account link"
                      aria-describedby="twitter"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="youtube">YouTube</label>
                    <input
                      type="text"
                      value={youtube}
                      onChange={(e) => setYoutube(e.target.value)}
                      className="form-control"
                      id="youtube"
                      placeholder="Enter your YouTube account link"
                      aria-describedby="youtube"
                    />
                  </div>
                </div>
              )}
            </div>
            <div></div>
          </div>
          {success && (
            <div className="alert alert-success" role="alert">
              Success!{" "}
              {type === "add"
                ? `The ${role} has been added`
                : "The user's account has been updated"}
            </div>
          )}
          {error && (
            <div className="alert alert-danger" role="alert">
              {errorMesssage !== ""
                ? errorMesssage
                : "Something went wrong. Please fill all the details correctly"}
            </div>
          )}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          <h3>PASSWORD</h3>
          {type === "add" ? (
            <div className="password-inputs">
              <div className="form-group">
                <label htmlFor="new-password">
                  New Password <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="form-control"
                  id="new-password"
                  placeholder="New Password"
                  aria-describedby="newPassword"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirm-password">
                  Confirm New Password <span className="text-danger">*</span>
                </label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-control"
                  id="confirm-password"
                  placeholder="Confirm the new Password"
                  aria-describedby="confirmPassword"
                  required
                />
              </div>
            </div>
          ) : (
            <div className="password-inputs">
              <div className="form-group">
                <label htmlFor="new-password">New Password</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="form-control"
                  id="new-password"
                  placeholder="New Password"
                  aria-describedby="newPassword"
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirm-password">Confirm New Password</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-control"
                  id="confirm-password"
                  placeholder="Confirm the new Password"
                  aria-describedby="confirmPassword"
                />
              </div>
            </div>
          )}
        </div>
        <button type="submit" className="btn btn-success m-2">
          {type === "add" ? "Add a dealer" : "Update"}
        </button>
      </form>
    </div>
  );
};

export default EditDealerListing;
