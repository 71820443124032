import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const Protected = ({ children }) => {
    const auth = useSelector((state) => state.admin.authenticated);
    if (!auth) {
        return <Navigate to="/login" />;
    }
    return children;
};
export default Protected;