import { initialStore } from "./store";
import {
  LOGIN,
  LOGOUT,
  GET_DEALERS,
  GET_WAVS,
  CHECK_LOGIN,
  GET_DEALER,
  GET_MAKES,
  GET_MODELS,
} from "./constants";
export const adminReducer = (state = initialStore.admin, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        ...state,
        authenticated: true,
        token: action.payload,
      };
    }
    case CHECK_LOGIN: {
      return {
        ...state,
        authenticated: true,
        token: action.payload,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        authenticated: false,
        token: "",
      };
    }
    case GET_DEALERS: {
      return {
        ...state,
        dealers: action.payload,
      };
    }
    case GET_DEALER: {
      return {
        ...state,
        selected: action.payload,
      };
    }
    case GET_WAVS: {
      return {
        ...state,
        wavs: action.payload,
      };
    }

    case GET_MAKES: {
      return {
        ...state,
        makes: action.payload,
      };
    }

    case GET_MODELS: {
      return {
        ...state,
        models: action.payload,
      };
    }

    default:
      return state;
  }
};
