import "./App.css";
import Admin from "./components/Admin/Admin";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
// import ProtectedRoute from "./components/ProtectedRoute";
import AdminSignIn from "./components/Login/Login";
import { useEffect } from "react";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { CHECK_LOGIN } from "./redux/constants";
import Protected from "./components/Protected";

function App() {
  // const cookies = new Cookies();
  // const jwt = cookies.get("jwt");
  // const user = cookies.get("user");
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   if (jwt && user) {
  //     dispatch({ type: CHECK_LOGIN, payload: jwt });
  //   }
  // }, []);

  const router = createBrowserRouter(
    [
      {
        path: "/",
        element: <Protected>
          <Admin />
        </Protected>
      },
      {
        path: "/login",
        element: <AdminSignIn />
      }
    ]
  )

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div >
  );
}

export default App;
